import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";

import { SvgIcon } from "@material-ui/core";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

export class WorksGallery extends Component {
  importAll(r) {
    return r.keys().map(r);
  }
  static propTypes = {};

  state = {
    currentFilter: "",
    showImageModal: false,
    modalImage: null,
    currentIndex: null,
  };

  mount = null;

  closeImageModal = () => {
    this.setState({
      showImageModal: false,
      modalImage: null,
    });
  };

  openImageModal = (item, index) => {
    this.setState({
      showImageModal: true,
      modalImage: item,
      currentIndex: index,
    });
  };

  nextImage = () => {
    const { images } = this.props;
    const { currentIndex } = this.state;

    if (currentIndex < images.length - 1) {
      const index = currentIndex + 1;
      this.setState({
        modalImage: images[index],
        currentIndex: index,
      });
    }
  };

  prevImage = (index) => {
    const { currentIndex } = this.state;

    if (currentIndex > 0) {
      index = currentIndex - 1;
      this.setState({
        modalImage: this.props.images[index], // TODO: get current image from index instead of saving it in the state !!!
        currentIndex: index,
      });
    }
  };

  componentDidMount() {
    if (this.mount?.parentNode)
      this.mount.parentNode.parentNode.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }

  render() {
    // console.log('this.state.modalImage', this.state.modalImage);
    return (
      <>
        <div
          ref={(mount) => {
            this.mount = mount;
          }}
          className="bathroomGallery"
        >

          <h2 style={{ marginBottom: '80px' }}>Project Gallery</h2>
          <br/><br/>
          <Grid container spacing={0} justifyContent={"center"}>
            {this.props.images
              ? this.props.images.map((image, index) => (
                  <div key={index} className="bathroomImg">
                    <img
                      key={index}
                      src={image.default}
                      alt="info"
                      onClick={() => this.openImageModal(image, index)}
                    />
                  </div>
                ))
              : null}
          </Grid>
        </div>

        {this.state.showImageModal && (
          <div className="lightboxandcover">
            <div className="lightBox">
              <img src={this.state.modalImage?.default || this.state.modalImage} alt="our work" />
              <p className="x-button" onClick={this.closeImageModal}>
                <SvgIcon component={CloseRoundedIcon} />
              </p>
              <p className="next-button" onClick={this.nextImage}>
                <SvgIcon component={KeyboardArrowRightRoundedIcon} />
              </p>
              <p className="prev-button" onClick={this.prevImage}>
                <SvgIcon component={KeyboardArrowLeftRoundedIcon} />
              </p>
            </div>
            <div className="cover"></div>
          </div>
        )}
      </>
    );
  }
}
