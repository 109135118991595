import React, { useRef } from "react";
import Slider from "react-slick";

import LogoGoogle from "../../../Assets/images/windows/home/whatTheySay-google.png";
import ArrowLeft from "../../../Assets/images/windows/home/arrow-whatSay-left.png";
import ArrowRight from "../../../Assets/images/windows/home/arrow-whatsay-right.png";
import Qoutes from "../../../Assets/images/windows/home/qoutes-whatSay.png";
import RightArrow from "../../../Assets/images/windows/home/arrow-lightBlue-right.png";
import "./WhatTheySayHomeWindows.scss";
const sliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  centerMode: true,
  centerPadding: "0px",

  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const WhatTheySayHomeWindows = ({ items }) => {
  const sliderRef = useRef();

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: false,
  };
  return (
    <section className="whatTheySay">
      <div className="contentSection">
        <h2>What Your Neighbours Are Saying About Us</h2>
        <img className="logoGoogle" src={LogoGoogle} />
        <div className="sliderContainer">
          <div className="arrowContainer arrowLeft">
            <img src={ArrowLeft} onClick={prevSlide} />
          </div>
          <div className="testimonial-slider">
            <Slider
              {...sliderSettings}
              {...additionalSliderSettings}
              ref={sliderRef}
            >
              {items.map((item, index) => (
                <div className="box" key={index}>
                  <p>{item.text}</p>
                  <div className="contact">
                    <img src={Qoutes} />
                    <div className="contact__info">
                      <div className="contact__info_user">
                        <h4>{item.name}</h4>
                        <div className="rating">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                      <img className="contact__userPhoto" src={item.photo} />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="arrowContainer arrowRight">
            <img src={ArrowRight} onClick={nextSlide} />
          </div>
        </div>
        <div
          className="moreTestimonials"
          onClick={() => {
            window.open(
              "https://www.google.com/search?q=agm+renovations+google+reviews&oq=agm+renovations+google+reviews&aqs=chrome..69i57j0l4j69i60l3.3523j0j4&sourceid=chrome&ie=UTF-8#lrd=0x882b2dd8ee9962bd:0xd03d1da943a37695,1",
              "blank"
            );
          }}
        >
          <h4>More Testimonials</h4>
          <img src={RightArrow} />
        </div>
      </div>
    </section>
  );
};
