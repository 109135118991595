import React from "react";

import { WorkSteps } from "../../Containers/HowItWorks";
import { Helmet } from "react-helmet";

import { WindowsTopSection } from "../../Containers/HowItWorks/windows/WindowsTopSection";
import { DoorsNav } from "../../Containers/DoorsNav/DoorsNav";
import VinylWindows from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoors from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoors from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoors from "../../Assets/images/windows/how-it-works/garage.png";

import stepPeopleWithTree from "../../Assets/images/hiw_step_1.png";
import stepTaplet from "../../Assets/images/hiw_step_4.png";
import stepPeopleWithLeptop from "../../Assets/images/hiw_step_6.jpg";
import stepAssemblingWindow from "../../Assets/images/windows/how-it-works/assembling-window.png";

import bayBow from "../../Assets/images/windows/how-it-works/bay-bow.png";
import casement from "../../Assets/images/windows/how-it-works/casement.png";
import doubleHung from "../../Assets/images/windows/how-it-works/double-hung.png";
import pictureWindow from "../../Assets/images/windows/how-it-works/picture-window.png";
import slidingWindow from "../../Assets/images/windows/how-it-works/sliding-window.png";
import specialtyWindow from "../../Assets/images/windows/how-it-works/specialty-window.png";
import awningWindow from "../../Assets/images/windows/how-it-works/awning-window.png";

import { TypesOfWindows } from "../../Containers/HowItWorks/windows/TypesOfWindows";
import { WindowsEnjoy } from "../../Containers/HowItWorks/windows/WindowsEnjoy";
import { VideoTestimonials } from "../../Containers/OurWork";

import prjvprv1 from "../../Assets/images/vidprev2.jpg";
import prjvprv2 from "../../Assets/images/vidprev3.jpg";
import prjvprv3 from "../../Assets/images/vidprev4.jpg";

import prjvprv4 from "../../Assets/images/roofs1.jpg";
import prjvprv5 from "../../Assets/images/roofs2.jpg";
import prjvprv6 from "../../Assets/images/roofs3.jpg";

import previewVideo from "../../Assets/videos/AGM Renovations - Windows Temperature Test.mp4";

export const HowItWorksPage = () => {
  const typesWindowsData = [
    {
      id: 0,
      img: doubleHung,
      title: "Double Hung Window",
      description: `Double hung windows boast a clean, classic design and have a wide range of design options. With its 
      flexible design, this window gives you complete control over the airflow in your home 
      and maximizes access, making cleaning effortless by easily exposing both sides of this window from top to bottom!`,
    },
    {
      id: 1,
      img: casement,
      title: "Casement Window",
      description: `With an elegant design, casement windows swing out horizontally using a smooth hand crank and have 
      become a popular selection for many. 
      Casement windows offer plenty of ventilation as their outward design easily directs more air into your home.`,
      revert: true,
    },
    {
      id: 2,
      img: bayBow,
      title: "Bay/Bow Window",
      description: `These large windows are an impressive display of glass, allowing for stunning, widespread views. Bay windows have sharp, clean corners with a large center pane and two smaller
      Explore Your AGM Window Options
      Double Hung Window
      windows on either side, while bow windows are designed in a more elegant outward arch, giving you a round, seamless appearance.`,
    },
    {
      id: 3,
      img: pictureWindow,
      title: "Picture Window",
      description: `These windows are designed to give any room a picturesque view, with elegant lines breaking up the window for a breathtaking appearance. Picture windows offer a classic appeal to any room and 
      are available in a variety of colours, giving you complete control over their effect in any room.`,
      revert: true,
    },
    {
      id: 4,
      img: slidingWindow,
      title: "Sliding Window",
      description: `With their narrow edges, sliding windows offer wide, stunning views and allow for ample light to flow into any room. These windows have an advanced design that includes an insulated edge to keep heat 
      inside when closed and slide open to expose one entire half of the window for maximum airflow when desired.`,
    },
    {
      id: 5,
      img: specialtyWindow,
      title: "Specialty Window",
      description: `These windows are unlike any other, designed in unique ways that create 
      awe-inspiring appeal to your home. These windows break out of the traditional square design and include elegant curves, providing flexibility to stand alone as 
      a gorgeous statement piece or be paired with other styles, creating a designer window.`,
      revert: true,
    },
    {
      id: 6,
      img: awningWindow,
      title: "Awning Window",
      description: `Outfitted with triple-pane glass and designed to sit flush with the outside frame, these windows are an energy-efficient option that offers maximum comfort. With its unique horizontal design, you’ll easily protect 
      your windowsills from the elements while providing ventilation whenever you need it.`,
    },
  ];
  const doorMenu = [
    {
      id: 0,
      img: VinylWindows,
      alt: "vinyl-windows",
      title: "Windows",
      path: "vinyl-windows",
    },
    {
      id: 1,
      img: EntryDoors,
      alt: "entry-doors",
      title: "Entry Doors",
      path: "patio-doors",
    },
    {
      id: 2,
      img: PatioDoors,
      alt: "",
      title: "Patio Doors",
      path: "patio-doors",
    },
    {
      id: 3,
      img: GarageDoors,
      alt: "",
      title: "Garage Doors",
      path: "garage-doors",
      left: "-48px",
      top: "-8px",
    },
  ];

  const workStepsItems = [
    {
      id: 0,
      title: "Receive Your Free Quote",
      description: `After answering a few quick questions, we’ll have a good understanding of your needs and design a personalized quote for your project.`,
      imgProps: {
        src: stepTaplet,
      },
    },
    {
      id: 1,
      title: "Select Your Project Start Date",
      description:
        "Choose a starting date for your project that works best for you.",
      imgProps: {
        src: stepPeopleWithTree,
      },
    },
    {
      id: 2,
      title: "Pick Your New Windows",
      description: `Explore your window options, and our expert designers will help you choose the ones that are best for your home to make your vision come to life.`,
      imgProps: {
        src: stepPeopleWithLeptop,
      },
    },
    {
      id: 3,
      title: "Installation Begins!",
      description: `Our team of professional installation technicians will get started on installing your new windows!`,
      imgProps: {
        src: stepAssemblingWindow,
      },
    },
  ];

  return (
    <>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
          <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
          <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
          <meta name="robots" content="index"/>
        </Helmet>
      <DoorsNav doorMenu={doorMenu} />
      <WindowsTopSection />
      <VideoTestimonials items={[
            { videoId: 'Tt_4YBT5icA', previewVideo: previewVideo },
            { videoId: 'kQB_md8tHuw', previewImage: prjvprv1 },
            { videoId: 'NZl-FVthimY', previewImage: prjvprv2 },
            { videoId: 'dplgoc_S6tA', previewImage: prjvprv3 },

            // { videoId: 'BF7jxIU5hBI', previewImage: prjvprv4 },
            // { videoId: 'D73N0ny-PKs', previewImage: prjvprv5 },
            // { videoId: 'GI9dZ_qx-qs', previewImage: prjvprv6 },
       ]} />
      <WorkSteps items={workStepsItems} isWindows={true} />
      <TypesOfWindows typesWindowsData={typesWindowsData} />
      <WindowsEnjoy text="Give Your Home a Fresh New Look" />
    </>
  );
};
