import React from 'react';
import Reviews from '../../Containers/Reviews';

import TestimonialsReviewerTwo from '../../Assets/testimonials_images/3.png';
import TestimonialsReviewerThree from '../../Assets/testimonials_images/pic-03.png';
import TestimonialsReviewerFour from '../../Assets/testimonials_images/4.png';
import TestimonialsReviewerFive from '../../Assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from '../../Assets/testimonials_images/12.png';
import TestimonialsReviewerSeven from '../../Assets/testimonials_images/10.png';

const testimonialsItems = [
    {
      text: `We got our washroom upgraded and they did a great job. They finished the job earlier than we were originally told without compromising on the quality of work. Would definitely recommend them!`,
      name: 'Ambreen Aslam',
      photo: TestimonialsReviewerTwo,
    },
    {
      text: `We are very pleased with AGM beginning with our sales consultant, Eric Burton and through to completion with the Project Manager, Igor. From start to finish, it has been nothing but quick responses, outstanding quality and just overall great experience. We are absolutely thrilled with our new kitchen. Thank you AGM.`,
      name: 'Julia Malette',
      photo: TestimonialsReviewerFour,
    },
    {
      text: `AGM basement did really good job while finishing our basement ! But it took a bit time more as they anticipated .  Everyworkers were perfect for their work ! Special thanks to the Designer ..... she did excellent !`,
      name: 'Rafy',
      photo: TestimonialsReviewerThree,
    },
    {
      text: `I was very happy with my painting project. I would recommend using AGM. The new colours freshened and updated my home. They did an awesome job!`,
      name: 'Jill Tay',
      photo: TestimonialsReviewerSix,
    },
    {
      text: `It was a great experience dealing with the professional team at AGM Renovation. The team lead by the Project Manager, Karim Fahim was exceptional to deal with. The project was delivered on time and budget. The team was responsive to all our queries and concerns. The trades who worked on our basement were top notch. I would recommend AGM Renovation without reservation.`,
      name: 'Jaideep Karkhanis',
      photo: TestimonialsReviewerSeven,
    },
    {
      text: `We got AGM to do our basement project that started on April21st and the project completed on June 13th 2023. Karim, our PM did a fantastic job to ensure the project finishes on time.`,
      name: 'Nithin Nayak',
      photo: TestimonialsReviewerFive,
    }
];

export const ReviewsPage = (props) => {

  return (
    <>
        <Reviews
            loopItems={testimonialsItems}
            paragraph={''}
            title={'AGM Renovations Reviews | AGM Windows-Doors Renovations | AGM Renovations Reviews – AGMWindows-Doors.com'}
            description={'Click here to see what AGM Renovations reviews have to say about the window and doors expertise of AGM Renovations.'}
            keywords={'AGM Renovations reviews'}
        >
          <p style={{ textAlign: "center" }}>
            AGM Renovations is a renowned provider of high-quality window and door replacement services. Our
            reputation precedes us, as many of our satisfied clients have attested to the excellence of our work through
            AGM Renovations reviews. We take pride in the fact that we are the only window and door replacement
            company recommended by Mike Holmes of &quot;Holmes on Homes&quot;.
            <br/><br/>
            Our commitment to delivering exceptional workmanship is evident in the many positive AGM Renovations
            reviews we have received. We believe in using top-quality materials and adhering to strict timelines to ensure
            that our clients receive the best possible service.
            <br/>
            At AGM Renovations, transparency and honesty are values we hold in high regard. We offer our clients the
            following guarantees to ensure their peace of mind:
            <br/><br/>
            <strong>Quoted Price Guarantee:</strong> We guarantee that there are no hidden fees or additional costs in our window and
            door services. As long as you don&#39;t change the scope of work you selected, your quoted price for our window
            and door services is guaranteed. Any additional costs will be absorbed by us.
            <br/><br/>
            <strong>Timeline Guarantee:</strong> We are committed to completing your project on time. If we are late, we will pay
            $100.00 for every business day we exceed the agreed timeline.
            <br/><br/>
            <strong>20-Years UNLIMITED Warranty:</strong> Our clients&#39; projects are guaranteed and warranted with a 20-years unlimited
            warranty that covers 100% of everything we do. We take pride
          </p>
        </Reviews>
    </>
  )

}