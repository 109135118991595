import React, { Component, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import './datepicker.scss';
import history from '../../history';
import { withRouter } from "react-router-dom";
import { TextField } from "@material-ui/core";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Input } from '@mui/material';
import Button from "@material-ui/core/Button";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dropzone from 'react-dropzone';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Troubleshoot from './Troubleshoot';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import GeneralBanner from "../../Common/GeneralBanner";

import CustomerInfo from "./WarrantySteps/CustomerInfo";
import UploadFiles from "./WarrantySteps/UploadFiles";
import Issues from "./Issues";
import Payments from "./WarrantySteps/Payments";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import filesFetcher from "../../Services/filesFetcher";
import projectsFallback from './projectsFallback.json';

import MuiAlert from '@material-ui/lab/Alert';

// const Submit_API = window.location.hostname === 'localhost' 
// 	? 'http://localhost:8080/api/submitForm'
// 	: `https://bvcolyw7q7ksjvk5yswtnppyju0qwgpf.lambda-url.us-east-2.on.aws/api/submitForm`;
	

const useStyles = makeStyles((theme) => ({
	container: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
	select: {
	  minWidth: '170px'
	}
  }));

function Alert(props) {
  return <MuiAlert 
    style={{
      position: 'absolute',
      margin: '0 5%',
      width: '90%',
      fontSize: '1em'
    }} 
    elevation={6} variant="filled" {...props} 
  />;
}

function DialogSelect({ setRenovationType }) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [projects, setProjects] = React.useState([]);

  React.useEffect(() => {
    const projectName = process.env.REACT_APP_NAME;
    const fetchServices = async () => {

      filesFetcher.fetchWarrantyEndpoints();
      // try {
      //   const servicesConfig = await filesFetcher.fetchWarrantyServices();
      //   setProjects(servicesConfig);

      //   if (process.env.REACT_APP_NAME) {
      //     const sameProj = servicesConfig.find( item => item.value === process.env.REACT_APP_NAME);
      //     setAge(sameProj.title);
      //     setRenovationType(sameProj.title);
      //   }

      // } catch (error) {
      //   console.log(`Error fetching projects JSON: ${error}`);
        setProjects(projectsFallback);

        if (projectName) {
          const sameProj = projectsFallback.find( item => item.value === projectName);

          if (sameProj) {
            setAge(sameProj.title);
            setRenovationType(sameProj.title);
          }
        }
      // }
    };

    fetchServices();
  
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
    setRenovationType(event.target.value);
    setOpen(false);
  };

  const handleClickOpen = () => {
    if (projects.length) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TextField
                  required
                  style={{ cursor: 'pointer' }}
                  variant="outlined"
                  fullWidth
                  type="text"
                  onClick={handleClickOpen}
                  value={age}
                  name="Project"
                  label="Renovation Project"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
      />

      <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle>{projects.length ? 'Select Renovation Project' : 'Loading Projects...'}</DialogTitle>
      { (projects && projects.length) ?  <DialogContent>
        <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Renovation Project</InputLabel>
                <Select
                  className={classes.select}
                  labelId="demo-simple-select-label"
                  value={age}
                  onChange={handleChange}
                >
                    {projects.map((item, index) => (
                      <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                    ))}
                </Select>
                </FormControl>
        </DialogContent> : null }

      </Dialog>
    </div>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d3c5a',
      darker: '#1d3c5a',
    },
  },
});


export class WarrantyAdvanced extends Component {

  static propTypes = {};

  state = {
    pre30: null,
    step: 1,
    visible: false,
    loading: false,
    date: null,
    data: {},
    modalOpen: null,
		project: '',
		showAlert: false,
    showSuccess: false,
  };

  submitWarrantyIssue = async (data) => {

    let { first_name, last_name, phone, email, issue_files, type_of_issue, description } = this.state.data;
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("mobile_number", phone);
    formData.append("email_address", email);
    formData.append("street_address", data.street_address);
    formData.append("street_address_line_two", data.street_address_line_two);
    formData.append("city", data.city);
    formData.append("state_province_region", data.state_province_region);
    formData.append("postal_code", data.postal_code);
    formData.append("type_of_issue", type_of_issue);
    formData.append("description", description);

		formData.append("renovation_project", this.state.project);
		formData.append("submission_date", new Date().toISOString().split('T')[0] );

    if (data.order_id) {
      formData.append("order_id", data.order_id);
    }

    if (this.state.date && moment().diff(this.state.date, 'days') < 30) {
      formData.append("before_30_days", "Yes");
    } else {
      formData.append("after_30_days", "Yes");
    }

    if (data.warrany_files && data.warrany_files.forEach) {
      data.warrany_files.forEach((file) => {
        formData.append("warranty_files", file);
      });
    }
    // console.log('issue_files', issue_files, data );

    if (issue_files && issue_files.forEach) {
      issue_files.forEach((file) => {
        formData.append("issue_files", file);
      });
    }

    formData.append("website_source", process.env.REACT_APP_TITLE);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    // const on_hold_dates = [
    //   {start: [2023, 9, 2],  end: [2023, 9, 6]},
    //   {start: [2023, 9, 23], end: [2023, 9, 27]}
    // ];

    // const todaysDate = sessionStorage.getItem('todaysDate')
    // ?  JSON.parse(sessionStorage.todaysDate)
    // : [
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     new Date().getDate()
    // ];
    
    // if (on_hold_dates.some(item => {
    //   if (item.start[0] <= todaysDate[0] && item.end[0] >= todaysDate[0]) {
    //     if (item.start[1] <= todaysDate[1] && item.end[1] >= todaysDate[1]) {
    //       if (item.start[2] <= todaysDate[2] && item.end[2] >= todaysDate[2]) {
    //         return true;
    //       }
    //     }
    //   }
    // })) {
    //   await fetch(
    //     `https://hooks.zapier.com/hooks/catch/2915270/3ir72zk/`, {
    //       method: "POST",
    //       body: JSON.stringify({
    //         name: `${first_name} ${last_name}`,
    //         email
    //       }),
    //     }
    //   );
    // }

		const endpoints = await filesFetcher.fetchWarrantyEndpoints();
		let Submit_API = endpoints && endpoints.url;
	
		if (sessionStorage.getItem('use_new_endpoint') || !Submit_API) {
		  Submit_API = `https://bvcolyw7q7ksjvk5yswtnppyju0qwgpf.lambda-url.us-east-2.on.aws/api/submitForm`;
		}

		try {
			const res = await fetch( Submit_API, requestOptions ).then( response => response.json() );
	  
			if (res.success !== false) {
			  
			  this.setState({ loading: false, data: {}, showSuccess: true  });
			  // window.location = "/thank-you";

        setTimeout(() => {
          this.setState({ showSuccess: false, step: 1 });
          window.location.reload();
        }, 5000);

			} else {
	  
			  this.setState({ loading: false, showAlert: true });
	  
			  setTimeout(() => {
				this.setState({ showAlert: false });
			  }, 5000);
			}
	  
		} catch (e) {
			this.setState({ loading: false, showAlert: true });
	  
			setTimeout(() => {
			  this.setState({ showAlert: false });
			}, 5000);
		}
    // const response = await fetch(
    //   `https://agm-warrantyform-api.herokuapp.com/api/submitForm`,
    //   requestOptions
    // );

    // try {
    //   const res = await response.json(); // If returned as JSON string
 
    //   if (res.success !== false) {
    //     this.setState({ loading: false, data: {} });
    //     // history.push("/thank-you");
    //     window.location = "/thank-you";
    //   } else {
    //     this.setState({ loading: false });
    //   }
    // } catch (e) {
    //   this.setState({ loading: false });
    // }
  };
  changeRadial = (event) => {
    let value = event.target.value
    if (value === 'after30') {
      this.setState({ pre30: false, visible: true });
    } else {
      this.setState({ pre30: true, visible: true });
    }
  }
  scrollToForm = () => {
    document.getElementById("FAQ").scrollIntoView({
      behavior: "smooth",
    });
  };

  validateSteps = (v) => {
    let { step, data } = this.state;
    let { date } = this.state;
    if (step === 1) {
      if (!v || !v.length) {
        this.setState({ hasWarrantyFileError: true })
      }
      return !['first_name', 'last_name', 'email', 'phone', 'address'].some(s => !data[s]) && Boolean(date) && v.length
    } else if (step === 2) {
      return (v.issue_files && v.issue_files.length) && v.type_of_issue && v.description
    }
    return true
  }

  stepComplete = (v) => {
    this.setState({ is_submitted: true })

    if (!this.validateSteps(v)) {
      return
    }

    const { step, data } = this.state;

    if (step === 1) {
      this.setState(
        {
          data: { ...data, warrany_files: v },
        }, () => {
          this.setState({ step: 2 });
        }
      );
    } else if (step === 2) {
      let fromNow = moment().diff(this.state.date, 'days')
      this.setState({
        data: {
          ...this.state.data,
          ...v
        }
      }, () => {
        if (fromNow > 30) {
          this.setState({ step: 3 })
        } else {
          this.submitWarrantyIssue(data);
        }
      })
    } else if (step === 3) {
      data.order_id = v.order_id
      this.setState(
        {
          success: data,
          loading: true,
        }, () => {
          this.submitWarrantyIssue(data);
        }
      );
    }
  };
  onDrop = (files) => {
    this.setState({
      data: {
        ...this.state.data,
        issue_files: [...(this.state.data.issue_files || []), ...files]
      }
    })
  };

  handleText = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    })
  }

  toggleModal = (v) => {
    if (this.state.modalOpen) {
      this.setState({ modalOpen: false })
    } else {
      this.setState({ modalOpen: v })
    }
  }
  modalContent = () => {
    if (!this.state.modalOpen) {
      return <></>
    }
    let content, title, header
    if (this.state.modalOpen === 'covered') {
      content = <><ul>
        {process.env.REACT_APP_NAME === 'kitchens' ? (
          <>
            <li>Deficiencies and defects visible from less than 5' under natural linting conditions.</li>
            <li>Faulty Hinges.</li>
            <li>Sliding Drawer Hardware (Rollers).</li>
            <li>No burn/Fire cabinets will be covered. </li>
            <li>Sinks free of dents or major scratches. Minor surface scratches are not covered.</li>
            <li>Door alignment to be adjusted once. </li>
            <li>All backsplash area around water will be caulked. Client to complete regular checks of caulking to ensure no water penetration. </li>
            <li>Faucet leak free</li>
            <li>Free flowing sinks</li>
            <li>P-traps leak free</li>
            <li>Drywall deficiencies- seen at 6ft in natural lighting conditions will be repaired, sanded, and painted with original paint choice. </li>
            <li>Paint finish Pearl finish used in all kitchens</li>
          </>
        ) : (
          <>
            <li>Deficiencies and defects visible from less than 5' under natural linting conditions.</li>
            <li>Faulty Hinges.</li>
            <li>Sliding Drawer Hardware (Rollers).</li>
            <li>Sinks free of dents or major scratches.</li>
            <li>Door alignment to be adjusted once. </li>
            <li>Shower floors slopped to allow for proper drainage</li>
            <li>All tilled area around water will be caulked. Client to complete regular checks of caulking to ensure no water penetration. </li>
            <li>Bathtubs to be free of any dents or scratches that are seen with normal lighting conditions.</li>
            <li>Shower faucets do not drip after use. </li>
            <li>Free flowing toilets and sinks </li>
            <li>P-traps leak free</li>
            <li>Drywall deficiencies- seen at 6ft in natural lighting conditions will be sanded and repainted in the original paint choice. </li>
            <li>Paint finish Pearl finish used in all bathrooms.</li>
          </>
        )}
      </ul>
      <hr className="warranty-hr" />
      <p>Please refer to www.tarion.com all warranties are followed by Tarion’s Construction performance guidelines.</p>
      </>
      // title = 'FAQs'
      header = 'What’s covered under your warranty'
    }
    else if (this.state.modalOpen === 'not_covered') {
      content = <><ul>
        {process.env.REACT_APP_NAME === 'kitchens' ? (
          <>
            <li>Deficiencies and defects not visible from 5' or more under natural linting conditions.</li>
            <li>With wood doors the humidity needs to be standard temperature or doors will split and crack.</li>
            <li>With MDF Therma foil doors. If melted or soaked with water. Then the material will peel.</li>
            <li>With interior melamine. If soaked with water material will bubble. </li>
            <li>Countertops Melamine/Laminate . If soak with water for to long material will peal off. The counters are not waterproof only water resistant. Normally they put a sticker that says customer to remove. It Reads the simple disclaimer that they are not waterproof.</li>
            <li>Hinges or door ripped of cabinet.</li>
            <li>We will not cover unlevelled doors as the client can adjust them their selves. These tend to wear out a bit and will have to be re leveled.</li>
            <li>We will only cover unleveled doors if it was not done within the 30 days.</li>
            <li>Damage to sink or plumbing if misused is not covered</li>
            <li>Cabinets that break apart will have to be covered.</li>
            <li>Reveal trim or crown molding with cabinets touching ceiling and have dap and dap split do to settling will not be covered.</li>
            <li>Kick plate that has water damaged will not be covered. (To much water on the floor. </li>
            <li>Doors that color becomes faded due to lack of cleanliness.</li>
            <li>Granite or stone countertop that split due to pressure cracks or misused.</li>
            <li>Stains on countertops</li>
            <li>Grease from hood range that is clogged in motor due to uncleanliness.</li>
            <li>Scratched or dented cabinet material.</li>
            <li>If toaster or candle or any machine that produces heat is left on this will melt the cabinets. We will not cover.</li>
            <li>Shelf clips will not be covered as they break because they are plastic. They can also be purchased at any hardware store.</li>
            <li>Paint pealing off backsplash from heat or water damage. </li>
            <li>Clogged sinks.</li>
            <li>Faucet Tap clogs due to sediment in air-rater.</li>
            <li>Melted plugs due to misuse or improper voltage machine.</li>
            <li>Light bulbs.</li>
            <li>Paint finish Pearl finish used in all kitchen projects.</li>
            <li>Cracks, nail pops, provided that  indoor humidity is below 35% upon at time of warranty inspection</li>
            <li>Scratches on the walls due to moving in/out.</li>
            <li>Flooring/Baseboard: lifting or separation of any boards by humidity</li>
            <li>The flooring/baseboard may also be affected by low humidity.</li>
            <li>Chips/scratches on the floor.</li>
            <li>HVAC: We do not do duct cleaning- Replace your furnace filter upon project completion.</li>
            <li>Electrical: GFI Switches that are tripped are not covered under warranty. Always push the test/reset buttons before calling us. If this is the repair that is needed, and you call our electricians on site, your deposit is forfeit.</li>
            <li>Plumbing</li>
            <li>Clogs and blockages of any kind are not warrantable. We tie into your existing plumbing to Ontario Building Code, and from time to time there may be blockages. This is rarely the result of our construction. Should you need your pipes cleaned, call a service plumber. If they determine that the blockage is a result of our construction, we will need an invoice from the plumbing company proving so, and we will reimburse the bill.</li>
            <li>Your existing plumbing that we tied into is not covered. We do a thorough inspection prior to construction but ultimately what exists in your house is your responsibility.</li>
            <li>Please ensure you have sufficient access to the garage/backyard water shutoffs before the project is complete. Client is responsible to close water valves during freezing temperatures, and AGM recommends that you also remove/detach the hoses on the exterior of the house as well. If your water shutoffs or any pipe that we have installed up to the water shutoff bursts as a result of freezing, this is not covered under our warranty. You may be able to make an insurance claim for any damages regarding this; please check with your home insurance provider to find out.</li>
            <li>Lightbulb replacement.</li>
          </>
        ) : (
          <>
            <li>Deficiencies and defects not visible from 5' or more under natural linting conditions.</li>
            <li>With MDF Therma foil doors. If melted or soaked with water. Then the material will peel.</li>
            <li>Countertops Melamine/Laminate . If soak with water for to long material will peal off. The counters are not waterproof only water resistant. Normally they put a sticker that says customer to remove. It Reads the simple disclaimer that they are not waterproof.</li>
            <li>Hinges or door ripped of vanity.</li>
            <li>We will not cover unlevelled doors as the client can adjust them their selves. These tend to wear out a bit and will have to be re leveled.</li>
            <li>We will only cover unleveled doors if it was not done within the 30 days.</li>
            <li>Damage to sink or plumbing if misused is not covered</li>
            <li>Kick plate that has water damaged will not be covered. (To much water on the floor. </li>
            <li>Doors that color becomes faded due to lack of cleanliness. </li>
            <li>Stains on countertops</li>
            <li>Paint pealing off backsplash from heat or water damage. </li>
            <li>Faucet Tap clogs due to sediment in air-rater.</li>
            <li>Light bulbs.</li>
            <li>Paint finish Pearl finish used in all bathroom projects. </li>
            <li>Cracks, nail pops, provided that  indoor humidity is below 35% upon at time of warranty inspection</li>
            <li>Scratches on the walls due to moving in/out </li>
            <li>Flooring/Baseboard: lifting or separation of any boards by humidity</li>
            <li>-The flooring/baseboard may also be affected by low humidity.</li>
            <li>HVAC: We do not do duct cleaning- Replace your furnace filter upon project completion. </li>
            <li>Chips/scratches on the floor. </li>
            <li>Electrical: GFI Switches that are tripped are not covered under warranty. Always push the test/reset buttons before calling us. If this is the repair that is needed, and you call our electricians on site, your deposit is forfeit.</li>
            <li>Plumbing</li>
            <li>Clogs and blockages of any kind are not warrantable. We tie into your existing plumbing to Ontario Building Code, and from time to time there may be blockages. This is rarely the result of our construction. Should you need your pipes cleaned, call a service plumber. If they determine that the blockage is a result of our construction, we will need an invoice from the plumbing company proving so, and we will reimburse the bill.</li>
            <li>Your existing plumbing that we tied into is not covered. We do a thorough inspection prior to construction but ultimately what exists in your house is your responsibility.</li>
            <li>Please ensure you have sufficient access to the garage/backyard water shutoffs before the project is complete. Client is responsible to close water valves during freezing temperatures, and AGM recommends that you also remove/detach the hoses on the exterior of the house as well. If your water shutoffs or any pipe that we have installed up to the water shutoff bursts as a result of freezing, this is not covered under our warranty. You may be able to make an insurance claim for any damages regarding this; please check with your home insurance provider to find out.</li>
            <li>Lightbulb replacement.</li>
          </>
        )}
      </ul>
      <hr className="warranty-hr" />
      <p>Please refer to www.tarion.com all warranties are followed by Tarion’s Construction performance guidelines.</p>
      </>
      title = 'FAQs'
      header = 'What’s not covered under your warranty'
    } else if (this.state.modalOpen === 'troubleshoot') {
      content = <Troubleshoot />
      title = 'FAQs'
      header = 'Troubleshooting'
    }
    return (
      <Dialog
        open={this.state.modalOpen}
        onClose={this.toggleModal}
        className="warranty-modal"
        aria-labelledby="alert-dialog-title"
        fullWidth
        maxWidth={"lg"}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>{header}</h2> <i className="fal fa-times close-modal" onClick={this.toggleModal} />
          <h4>{title}</h4>
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
     
          <Button variant="outlined" onClick={this.toggleModal}>Close</Button>

        </DialogActions>
      </Dialog>
    )
  }

  onFileChange = () => {
    this.setState({ hasWarrantyFileError: false });
  }

  stepContents = (step) => {
    let { first_name, last_name, address, phone, email } = this.state.data
    let { is_submitted, date } = this.state
    if (step === 1) {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="above-warranty-form-holder">
            <Container maxWidth="lg" className="above-warranty-form">

              <div className="questions" style={{ marginTop: '20px' }}>
                <div className="question" onClick={() => this.toggleModal('covered')}>
                  <span>What’s covered under your warranty</span>
                  <i className="far fa-plus" />
                </div>

                <div className="question" onClick={() => this.toggleModal('not_covered')}>
                  <span>What’s not covered under your warranty</span>
                  <i className="far fa-plus" />
                </div>
              </div>

            </Container>
          </div>
          <Container maxWidth="lg" className="new-warranty-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3 className="text-center">Enter a few details to get started with your warranty claim.</h3>
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                  id="first_name"
                  required
                  variant="outlined"
                  fullWidth
                  onChange={this.handleText}
                  name="first_name"
                  label="First Name"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  errorMessage={is_submitted && !first_name ? 'Required' : null}
                  error={is_submitted && !first_name ? 'Required' : null}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="last_name"
                  required
                  variant="outlined"
                  fullWidth
                  onChange={this.handleText}
                  name="last_name"
                  label="Last Name"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  errorMessage={is_submitted && !last_name ? 'Required' : null}
                  error={is_submitted && !last_name ? 'Required' : null}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  required
                  variant="outlined"
                  fullWidth
                  type="email"
                  onChange={this.handleText}
                  name="email"
                  label="Email"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  errorMessage={is_submitted && !email ? 'Required' : null}
                  error={is_submitted && !email ? 'Required' : null}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="phone"
                  required
                  variant="outlined"
                  fullWidth
                  type="tel"
                  onChange={this.handleText}
                  name="phone"
                  label="Phone Number"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  errorMessage={is_submitted && !phone ? 'Required' : null}
                  error={is_submitted && !phone ? 'Required' : null}
                />
              </Grid>

							<Grid item xs={12} sm={6}>
              <DatePicker
                  inputVariant="outlined"
                  label="Date of Final completion"
                  required
                  fullWidth
                  disableFuture={true}
                  value={this.state.date}
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  onChange={(date) => this.setState({ date })}
                  error={is_submitted && !date ? 'Required' : null}
                />
							</Grid>
							<Grid item xs={12} sm={6}>
								<DialogSelect setRenovationType={(value) => { this.state.project = value; }} />
							</Grid>

              <Grid item xs={12}>
                <TextField
                  id="address"
                  required
                  variant="outlined"
                  fullWidth
                  onChange={this.handleText}
                  name="address"
                  label="Home Address"
                  inputProps={{ style: { fontSize: 21, fontFamily: 'Quicksand' } }}
                  InputLabelProps={{ style: { fontSize: 21, fontFamily: 'Quicksand', backgroundColor: 'white' } }} 
                  errorMessage={is_submitted && !address ? 'Required' : null}
                  error={is_submitted && !address ? 'Required' : null}
                />
              </Grid>

              <Grid item xs={12}>
                <UploadFiles
                  stepComplete={(data) => this.stepComplete(data)}
                  buttonTitle={`Continue`}
                  isFromWarranty
                  hasWarrantyFileError={this.state.hasWarrantyFileError}
                  onChange={this.onFileChange}
                />
              </Grid>
            </Grid>
          </Container>
        </MuiPickersUtilsProvider>
      )
    } else if (step === 2) {
      return (
        <Issues
          stepComplete={(data) => this.stepComplete(data)}
          loading={this.state.loading}
          pre30={this.state.pre30}
          buttonTitle={this.state.date && moment().diff(this.state.date, 'days') > 30 ? 'Continue' : 'Submit'}
        />
      )
    } else {
      return (
        <Container maxWidth="lg">
          <div className="step4">
            <Payments
              stepComplete={(data) => this.stepComplete(data)}
              loading={this.state.loading}
            />
          </div>
        </Container>)
    }
  }

  render() {

    const { step } = this.state;

    return (
      <>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE_WARRANTY}</title>
          <metanpm
            name="description"
            content="AGM Basements has a 4.5-star rating with over 200 Google Reviews as GTA’s top-rated basement renovations we provide 100% customer satisfaction."
          />
        </Helmet>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <div className="warranty-banner">
              <GeneralBanner title="Warranty" subTitle="Submit form below to open a warranty claim." styles={{textAlign: 'center'}} />
            </div>

            <div className="topMargin10">
              {this.stepContents(step)}
              {this.modalContent()}
            </div>

            {this.state.showAlert ? <Alert severity="error">Warranty submission request failed. Please try again!</Alert> : null}
            {this.state.showSuccess ? <Alert severity="success">We will get back to you soon. Thank You!</Alert> : null}


            {this.state.visible === true && (
              <div id="form-steps">
                <div
                  className={"step customer_info " + (step === 1 ? "current" : "")}
                >
                  <div className="count">1</div>
                  <p>Your Info</p>
                </div>

                <div
                  className={"step warranty_info " + (step === 2 ? "current" : "")}
                >
                  <div className="count">2</div>
                  <p>Warranty Info</p>
                </div>

                <div className={"step issue_info " + (step === 3 ? "current" : "")}>
                  <div className="count">3</div>
                  <p>Issue</p>
                </div>

                {this.state.pre30 === false && (
                  <div className={"step payment_info " + (step === 4 ? "current" : "")}>
                    <div className="count">4</div>
                    <p>Payment</p>
                  </div>
                )}
              </div>
            )}
            {this.state.visible === true && (
              <div id="form">
                <div className="container">
                  <form action="/" id="warranty-form" className="dropzone">
                    <div className="step1">
                      {this.state.step === 1 && (
                        <CustomerInfo
                          stepComplete={(data) => this.stepComplete(data)}
                        />
                      )}
                    </div>

                    <div className="step2">
                      {this.state.step === 2 && (
                        <UploadFiles
                          stepComplete={(data) => this.stepComplete(data)}
                        />
                      )}
                    </div>

                    <div className="step3">
                      {this.state.step === 3 && (
                        <Issues
                          stepComplete={(data) => this.stepComplete(data)}
                          loading={this.state.loading}
                          pre30={this.state.pre30}
                        />
                      )}
                    </div>

                    <div className="step4">
                      {this.state.step === 4 && (
                        <Payments
                          stepComplete={(data) => this.stepComplete(data)}
                          loading={this.state.loading}
                        />
                      )}
                    </div>

                  </form>
                </div>
              </div>
            )}
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </>
    );
  }
}

export default withRouter(WarrantyAdvanced);
