import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useBreakPoint } from "../../../hooks/useResponsive";

import "./EnjoyNewWindow.scss";

export const EnjoyNewWindow = ({ windowIcons, windowImages }) => {
  const [activeWindow, setActiveWindow] = useState(0);
  const breakpoint = useBreakPoint();
  return (
    <section className="enjoyNewWindow">
      {breakpoint !== 980 ? (
        <>
          <div className="enjoyTxt">
            <h2>Discover Your New Windows!</h2>
            <div className="enjoyTxt__windows">
              {windowIcons.map((item, index) => (
                <img
                  key={item.id}
                  className={index === activeWindow ? "active" : ""}
                  src={item.img}
                  alt={item.alt}
                  onClick={(e) => {
                    setActiveWindow(index);
                  }}
                />
              ))}
            </div>
            <Link to="/get-started" className="blue-dark-windows-btn">
              <button>Get Started</button>
            </Link>
          </div>
          {windowImages[activeWindow].map((item, index) => (
            <img
              key={index}
              className="wndType"
              src={item}
              alt={"windowImage"}
            />
          ))}
        </>
      ) : (
        <>
          <div className="enjoyTxt">
            <h2>Discover Your New Windows!</h2>

            {windowImages[activeWindow].map((item, index) => (
              <img
                className="wndType"
                key={index}
                src={item}
                alt={"windowImage"}
              />
            ))}

            <div className="enjoyTxt__windows">
              {windowIcons.map((item, index) => (
                <div key={item.id} className="enjoyTxt__windows_item">
                  <img
                    className={index === activeWindow ? "active" : ""}
                    src={item.img}
                    alt={item.alt}
                    onClick={(e) => {
                      setActiveWindow(index);
                    }}
                  />
                </div>
              ))}
            </div>
            <Link to="/get-started" className="blue-dark-windows-btn">
              <button>Get Started</button>
            </Link>
          </div>
        </>
      )}
    </section>
  );
};
