import React from "react";
import { StepWindowType } from "./StepWindowType";
import "./TypesOfWindows.scss";

export const TypesOfWindows = ({ typesWindowsData }) => {
  return (
    <section id="typesOfWindows">
      <h3>Explore Your AGM Window Options</h3>
      <div className="typeOfWindows__container">
        {typesWindowsData.map((item, index) => (
          <StepWindowType
            key={index}
            img={item.img}
            title={item.title}
            description={item.description}
            revert={item.revert}
          />
        ))}
      </div>
    </section>
  );
};
