import axios from "axios";
import { GoogleSpreadsheet, SpreadsheetApp } from "google-spreadsheet";

const loadSpreadsheetPage = async (
  docId = "16ftfcJbrm9wxMpXVa6RR1-1CyDa2zEfnqRxvsSMscPQ"
) => {
  // Initialize the sheet - doc ID is the long id in the sheets URL
  let locations = [],
    languages = [],
    agentType = [],
    lastActivity = [],
    items = [];

  const doc = new GoogleSpreadsheet(docId);

  try {
    // Initialize Auth - see https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
    await doc.useServiceAccountAuth({
      // env var values are copied from service account credentials generated by google
      // see "Authentication" section in docs for more info
      client_email: process.env.REACT_APP_GOOGLE_SHEET_EMAIL,
      private_key: process.env.REACT_APP_GOOGLE_SHEET_PRIVATE_KEY,
    });
    await doc.loadInfo(); // loads document properties and worksheets
    const worksheets = doc.sheetsByIndex[0],
      worksheetFilter = doc.sheetsByIndex[1];

    const rows = await worksheets.getRows(),
      filterdRows = await worksheetFilter.getRows();

    const rowValues = rows.filter((row) => row?._rawData?.length > 0);
    const rowFiltered = filterdRows.filter((row) => row?._rawData?.length > 0);

    rowValues.forEach((e) => {
      const { _rawData, _sheet } = e;
      const itemReal = {
        name: _rawData[0],
        phone: _rawData[1],
        countProjects: _rawData[2],
        locations: _rawData[3],
        languages: _rawData[4],
        agentType: _rawData[5],
        lastActivity: _rawData[6],
        photoId: _rawData[7],
        brokerageName: _rawData[8],
        email: _rawData[9],
        district: _rawData[10],
      };
      items.push(itemReal);
    });

    rowFiltered.forEach((e) => {
      const { _rawData, _sheet } = e;

      if (_rawData[0]) {
        locations.push(_rawData[0]);
      }
      if (_rawData[1]) {
        languages.push(_rawData[1]);
      }
      if (_rawData[2]) {
        agentType.push(_rawData[2]);
      }
      if (_rawData[3]) {
        lastActivity.push(_rawData[3]);
      }
    });
  } catch (error) {
    console.error(`Error loading spreadsheets from google.docs: ${error}`);
  }
  return { items, locations, languages, agentType, lastActivity };
};

export { loadSpreadsheetPage };
