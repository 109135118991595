import React from "react";
import { Link } from "react-router-dom";
import { breakpoints, useBreakPoint } from "../../hooks/useResponsive";
import "./TopIntro.scss";

export const TopIntro = ({
  backgroundImage,
  backgroundSm,
  backgroundMobile,
  title,
  subtitle2,
  subtitle,
}) => {
  const breakpoint = useBreakPoint();
  const background =
    breakpoint === 1440
      ? backgroundSm
      : breakpoint === 980
      ? backgroundMobile
      : backgroundImage;
  return (
    <section
      className="windowsIntroTop"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="windows__content">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        {subtitle2 && (
          <>
            <br />
            <h3>{subtitle2}</h3>
          </>
        )}
        <Link to="/get-started" className="blue-dark-windows-btn">
          <button>Get Started</button>
        </Link>
      </div>
    </section>
  );
};
