import React from "react";

import "./Color.scss";

const colorInfo = [
  {
    id: 0,
    title: "Slate",
    kind: "STANDARD",
    background: "#696e72",
    color: "#fff",
  },
  {
    id: 1,
    title: "Cashmere",
    kind: "STANDARD",
    background: "#e6e3de",
    color: "black",
  },
  {
    id: 2,
    title: "Chestnut Brown",
    kind: "STOCK",
    background: "#451f16",
    color: "#fff",
  },
  {
    id: 3,
    title: "Sable",
    kind: "STANDARD",
    background: "#6b5b4b",
    color: "#fff",
  },
  {
    id: 4,
    title: "Cream",
    kind: "STOCK",
    background: "#ebddba",
    color: "black",
  },
  {
    id: 5,
    title: "Black",
    kind: "STOCK",
    background: "#050505",
    color: "#fff",
  },
  {
    id: 6,
    title: "Green Forest",
    kind: "STANDARD",
    background: "#0a412e",
    color: "#fff",
  },
  {
    id: 7,
    title: "Pebble",
    kind: "STOCK",
    background: "#a99685",
    color: "#fff",
  },
];
export default function Color() {
  return (
    <section className="colorSection">
      <h4>
        We stock a selection of eight standard exterior colours and offer
        paint-to-order services and an interior colour-matching program for our
        operable casement, fixed casement, awning, and picture windows.
      </h4>
      <h5>
        *Colours may vary from those illustrated due to the printing process.
      </h5>
      <div className="colorContent">
        {colorInfo.map((item) => (
          <div
            key={item.id}
            style={{ backgroundColor: item.background }}
            className="colorSection__item"
          >
            <h3 style={{ color: item.color }}>{item.title}</h3>
            <h3 style={{ color: item.color }}>
              <b>{item.kind}</b>
            </h3>
          </div>
        ))}
      </div>
    </section>
  );
}
