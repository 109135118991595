import React from "react";

import { styled } from "@mui/system";

import Color from "./components/Color";

import "./ProductFeatures.scss";
import Grills from "./components/Grills";
import Casings from "./components/Casings";
import HardwareFinishes from "./components/HardwareFinishes";

import Tabs from "@mui/base/Tabs";
import TabsList from "@mui/base/TabsList";
import TabPanel from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import Tab, { tabClasses } from "@mui/base/Tab";

const blue = {
  400: "#3399FF",

  600: "#0072E5",
};

const StyledTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    color: ${blue[400]};
  }

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  width:100%;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `
);

const StyledTabs = styled(Tabs)(
  ({ theme }) => `
  width:100%;
 max-width:1440px
  `
);

export default function ProductFeatures() {
  return (
    <section className="productFeatures">
      <h2>Product Features</h2>
      <p className="subtitle">
        Our vinyl windows offer maximum customization, giving you control over
        your window casement colour, style of grills and casing, and your
        hardware finishes, so your windows can seamlessly match your home.
      </p>

      <StyledTabs defaultValue={1}>
        <StyledTabsList>
          <StyledTab value={1}>COLOUR</StyledTab>
          <StyledTab value={2}>GRILLS</StyledTab>
          <StyledTab value={3}>CASINGS</StyledTab>
          <StyledTab value={4}>HARDWARE FINISHES</StyledTab>
        </StyledTabsList>
        <StyledTabPanel value={1}>
          <Color />
        </StyledTabPanel>
        <StyledTabPanel value={2}>
          <Grills />
        </StyledTabPanel>
        <StyledTabPanel value={3}>
          <Casings />
        </StyledTabPanel>
        <StyledTabPanel value={4}>
          <HardwareFinishes />
        </StyledTabPanel>
      </StyledTabs>
    </section>
  );
}
