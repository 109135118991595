import React from "react";

export const TopSection = ({ subtitle, btnText }) => {
  return (
    <section className="video-intro about-us">
      <div className="container">
        <div className="cnt">
          <h2>About Us</h2>
          {subtitle ? <h4>{subtitle}</h4> : <h4>The Team</h4>}

          <div>
            <a href="/careers" className="blue-btn" rel="noopener noreferrer">
              {btnText ? `${btnText}` : " APPLY FOR JOB!"}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
