import React from 'react';

export const TopSection = ({ bannerImgProps, className }) => {
  return (
    <section className={`video-intro how-works ${className || ''}`}>
      <div className="banner">
        <div className="content relative">
          {sessionStorage.getItem('countryCode') === 'CA' && (
            <div className="center">
              <a href="https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply" target="_blank">Finance</a> your project
            </div>
          )}
          <div className="context">
            <h2>How it works</h2>
            {/* <h4>The Process</h4> */}
          </div>
          <div className="imgContainer">
            <img {...bannerImgProps}  />
          </div>
        </div>
      </div>
    </section>
  )
}
