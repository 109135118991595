import React from "react";
import "../Home/windows/WindowsHomeTop.scss";

import { WindowsIntro as CallToAction } from "../OurWork/windows/WindowsIntro";
import { DoorsNav } from "../DoorsNav/DoorsNav";

import { TopIntro } from "../../Common/ServicesPages/TopIntro";
import { Features } from "../../Common/ServicesPages/Features";
import OurColection from "../OurColection/OurColection";

import bcknd_top from "../../Assets/images/windows/garage/top_background.png";
import calltoaction_bcgnd from "../../Assets/images/windows/garage/garage_calltoaction.jpg";
import features_bcgnd from "../../Assets/images/windows/garage/garage_features.jpg";

import VinylWindowsImg from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoorsImg from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoorsImg from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoorsImg from "../../Assets/images/windows/how-it-works/garage.png";

import ShakerCC from "../../Assets/images/windows/garage/1 shaker-cc.jpg";
import Prestige from "../../Assets/images/windows/garage/2 prestige.jpg";
import CambridgeCm from "../../Assets/images/windows/garage/3 cambridge-cm.jpg";
import ClassicMix from "../../Assets/images/windows/garage/4 classic-mix.jpg";

import ClassicCc from "../../Assets/images/windows/garage/5 classic-cc.jpg";
import EastmanE11 from "../../Assets/images/windows/garage/6 eastman-e11.jpg";
import ClassicXl from "../../Assets/images/windows/garage/7 classic-xl.jpg";
import eastmanE12 from "../../Assets/images/windows/garage/8 eastman-e12.jpg";

import EastmanE13 from "../../Assets/images/windows/garage/9 eastman-e13.jpg";
import CambridgeCl from "../../Assets/images/windows/garage/10 cambridge-cl.jpg";
import California from "../../Assets/images/windows/garage/11 california.jpg";
import Vog from "../../Assets/images/windows/garage/12 vog.jpg";

import ModernoMulti from "../../Assets/images/windows/garage/13 moderno-multi.jpg";
import Grooved from "../../Assets/images/windows/garage/14 grooved.jpg";
import Moderno from "../../Assets/images/windows/garage/15 moderno.jpg";
import Flush from "../../Assets/images/windows/garage/16 flush.jpg";
import backgroundMobile from "../../Assets/images/windows/garage/background-top-mobile.png";
import WindowsSecondTop from "../../Assets/images/windows/garage/second-top-mobile.png";
import FeelingBackgroundMobile from "../../Assets/images/windows/garage/feeling-background-mobile-garage.png";

const checkList = [
  "Energy Efficient Windows And Doors",
  "Completing our projects within budget",
  "No added or hidden costs",
  "Decades of customer satisfaction",
];
const doorMenu = [
  {
    id: 0,
    img: VinylWindowsImg,
    alt: "vinyl-windows",
    path: "vinyl-windows",
    title: "Windows",
  },
  {
    id: 1,
    img: EntryDoorsImg,
    alt: "entry-doors",
    path: "entry-doors",
    title: "Entry Doors",
  },
  {
    id: 2,
    img: PatioDoorsImg,
    alt: "",
    path: "patio-doors",
    title: "Patio Doors",
  },
  {
    id: 3,
    img: GarageDoorsImg,
    alt: "",
    path: "garage-doors",
    title: "Garage Doors",
    left: "-48px",
    top: "-8px",
  },
];
const TopText = {
  title: "Garage Doors",
  subtitle: `Your garage door is a substantial aspect of your home's outward appearance and one of the first 
  things people see. Create a stunning, fresh exterior by adding an exceptionally designed and energy-efficient garage door.`,
  subtitle2: `Whether you want your new garage door to match your entryway or crave a classic design, we 
  offer a wide selection for your to pick from, ensuring we have a style to meet your needs.`,
};
const FeaturesText = {
  title: "Top-Quality Garage Doors in Ontario",
  subtitle: "",
  containerClass: "doors",
  checkList: [
    "Energy-efficient doors",
    "Installation completed on time",
    "Stays within budget",
    "No hidden or surprise costs",
    "Backed by decades of customer satisfaction",
  ],
};

const OurWorks = [
  { id: 0, img: ShakerCC, alt: "ShakerCC", title: "Shaker CC" },
  { id: 1, img: Prestige, alt: "Prestige", title: "Prestige XL" },
  { id: 2, img: CambridgeCm, alt: "CambridgeCm", title: "Cambridge CL" },
  { id: 3, img: ClassicMix, alt: "ClassicMix", title: "Classic Mix" },
  { id: 4, img: ClassicCc, alt: "ClassicCc", title: "Classic CС" },
  { id: 5, img: EastmanE11, alt: "EastmanE11", title: "Eastman E-11" },
  { id: 6, img: ClassicXl, alt: "ClassicXl", title: "Classic XL" },
  { id: 7, img: eastmanE12, alt: "eastmanE12", title: "Eastman E-12" },
  { id: 8, img: EastmanE13, alt: "EastmanE13", title: "Eastman E-13" },
  { id: 9, img: California, alt: "California", title: "California" },
  { id: 10, img: CambridgeCl, alt: "CambridgeCl", title: "Cambridge XL" },
  { id: 11, img: Vog, alt: "Vog", title: "Vog" },
  { id: 12, img: ModernoMulti, alt: "ModernoMulti", title: "Moderno Multi" },
  { id: 13, img: Grooved, alt: "Grooved", title: "Grooved" },
  { id: 14, img: Moderno, alt: "Moderno", title: "Moderno 2 Beads" },
  { id: 15, img: Flush, alt: "Flush", title: "Flush" },
];

const GarageDoors = (props) => {
  return (
    <>
      <section className="windowsHomeTop">
        <DoorsNav doorMenu={doorMenu} active={"garage-doors"} />
      </section>
      <TopIntro
        backgroundImage={bcknd_top}
        backgroundSm={bcknd_top}
        backgroundMobile={backgroundMobile}
        {...TopText}
      />
      <Features
        backgroundImage={features_bcgnd}
        imgMobile={WindowsSecondTop}
        checkList={checkList}
        {...FeaturesText}
      />
      <OurColection
        items={OurWorks}
        title={"Our Garage Doors Collection"}
        subtitle={"See how a new garage door can amplify your home!"}
      />
      <CallToAction
        backgroundImageIntro={calltoaction_bcgnd}
        backgrounMobileIntro={FeelingBackgroundMobile}
      />
    </>
  );
};

export default GarageDoors;
