import React, { useState } from "react";
import { BrowserRouter, Route, PublicPage } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ChatBox } from './Common/ChatBox';

import { Helmet } from "react-helmet";
// Parts
import Header from "./Common/header";
import Footer from "./Common/footer";
// End Parts

import GetStarted from "./Containers/GetStarted/";
import ThankYou from "./Containers/ThankYou/";
import Finishes from "./Containers/Finishes/";
import BathroomLayout from "./Containers/BathroomLayout/";
import GeoLocation from "./Containers/GeoLocation/";
import PrivacyPolicy from "./Containers/PrivacyPolicy";

import VinylWindows from "./Containers/vinylWindows/";
import EntryDoors from "./Containers/entryDoors/";
import PatioDoors from "./Containers/patioDoors/";
import GarageDoors from "./Containers/garageDoors/";
import RealEstate from "./Containers/RealEstate";
import Referrals from "./Containers/Referrals/";
import Blog from "./Containers/Blog/";
//import PdfDocument from "./Views/bathroomForm";
import setup from "./Views/setup";

import "./Assets/SCSS/main.scss";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import bathroomRoutes from "./routes/bathrooms";
import kitchenRoutes from "./routes/kitchens";
import windowsRoutes from "./routes/windows";
import { WindowsFooter } from "./Containers/HowItWorks/windows/WindowsFooter";
import Promotion from "./routes/windows/Promotion";

const theme = createTheme({
  palette: {
    primary: {
      light: "#46bfee",
      main: "#46bfee",
      dark: "#46bfee",
      contrastText: "#fff",
    },
    secondary: {
      main: "#46bfee",
    },
  },
});

function App(props) {
  const [geoData, setGeoData] = useState(null);
  let appRoutes;
  switch (process.env.REACT_APP_NAME) {
    case "windows":
      // console.log("apppname: windows");
      appRoutes = windowsRoutes;
      break;
    case "kitchens":
      // console.log("apppname: kitchenRoutes");
      appRoutes = kitchenRoutes;
      break;
    default:
      // console.log("apppname: bathroomRoutes");
      appRoutes = bathroomRoutes;
      break;
  }

  // console.log(appRoutes);
  // process.env.REACT_APP_NAME === "kitchens" ? kitchenRoutes : bathroomRoutes;

  // For passing as prop instead of declaring on each component
  // Still need to clean up old files. Just updating as I go for now
  const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
  const isWindows = process.env.REACT_APP_NAME === "windows";

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ScrollToTop>
          {isWindows ? (
            <Header
              isShowlogos={geoData && geoData.countryCode !== "US"}
              isWindows={true}
            />
          ) : (
            <Header isShowlogos={geoData && geoData.countryCode !== "US"} />
          )}

        <Helmet>
          <meta name="robots" content="noindex"/>
        </Helmet>

          {appRoutes.map((route) => {
            const { name, renderWithData, ...routeProps } = route;
            const renderProp = renderWithData
              ? {
                  render: () =>
                    renderWithData({
                      data: geoData,
                      isKitchenApp,
                      location: geoData,
                    }),
                }
              : {};
            return <Route key={name} {...routeProps} {...renderProp} />;
          })}
          <Route exact path="/get-started" component={GetStarted} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/finishes" component={Finishes} />
          <Route exact path="/bathroomlayout" component={BathroomLayout} />
          <Route exact path="/setup" component={setup} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/referrals" component={Referrals} />

          <Route exact path="/vinyl-windows" component={VinylWindows} />
          <Route exact path="/entry-doors" component={EntryDoors} />
          <Route exact path="/patio-doors" component={PatioDoors} />
          <Route exact path="/garage-doors" component={GarageDoors} />
          <Route exact path="/real-estate" component={RealEstate} />
          <Route exact path="/promotion-agm" component={Promotion} />
          <Route exact path="/design">
            <PublicPage />
          </Route>
          {/* <GeoLocation updateFunc={updateStateJson} data={jsonData} updateLocation={setLocation} /> */}
          <GeoLocation
            data={geoData}
            setGeoData={(geoDatanew) => setGeoData(geoDatanew)}
            updateUserLocation={() => {}}
            userLocationContact={null}
            visible={true}
          />
          <Footer data={geoData} />
          <ChatBox/>
          <a style={{ visibility: 'hidden', position: 'absolute' }} href="https://agmrenovationsreviews.com/" alt="AGM reviews" target="_blank"></a>
          <a style={{ visibility: 'hidden', position: 'absolute' }} href="https://agmrenovationsreviews.ca/" alt="AGM reviews" target="_blank"></a>
          
        </ScrollToTop>
      </ThemeProvider>
    </BrowserRouter>
  );

  function PublicPage() {
    return (window.location = process.env.PUBLIC_URL);
  }
}

export default App;
