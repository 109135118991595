import React from "react";

import "./StepWindowType.scss";
import { useBreakPoint } from "../../../hooks/useResponsive";

export const StepWindowType = ({ img, title, description, revert }) => {
  const breakPoint = useBreakPoint();
  return (
    <>
      {breakPoint == 980 ? (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      ) : revert !== true ? (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      ) : (
        <div className="windowsTypeContainer">
          <div className="windowsTypeContainer__txt">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="windowsTypeContainer__img">
            <img src={img} />
          </div>
        </div>
      )}
    </>
  );
};
