import React from "react";

import "./Casings.scss";

import checkedGreen from "../../../Assets/images/windows/vinil/checked-green.png";
import ColonialCasing from "../../../Assets/images/windows/vinil/colonial-casing.png";
import FlatCasing from "../../../Assets/images/windows/vinil/flat-casing.png";
import ColonialCasing45deg from "../../../Assets/images/windows/vinil/colonial-caing-45deg.png";

const items = [
  {
    id: 0,
    img: ColonialCasing,
    alt: "ColonialCasing",
    title: (
      <>
        100% Lead-Free
        <br />
        Durable Virgin UPVC
      </>
    ),
  },
  {
    id: 1,
    img: FlatCasing,
    alt: "FlatCasing",
    title: (
      <>
        100%
        <br />
        Maintenance Free
      </>
    ),
  },
  {
    id: 2,
    img: ColonialCasing45deg,
    alt: "ColonialCasing45deg",
    title: (
      <>
        Mouldable For Windows
        <br />
        Of Various Shapes
      </>
    ),
  },
];

export default function Casings() {
  return (
    <section className="casingSection">
      <h4>
        We offer a variety of casings designed to compliment your vinyl windows
        perfectly. Choose from snap-on casings, wood casings, and wood and vinyl
        jamb extensions accentuated with stylish rosettes or mitered corner
        casings.
      </h4>
      <div className="casingContent">
        {items.map((item) => (
          <div key={item.id} className="casingContent__item">
            <div className="casingContent__item_header">
              <img src={checkedGreen} alt="checked" />
              <h3>
                <b>{item.title}</b>
              </h3>
            </div>
            <img src={item.img} alt={item.alt} />
          </div>
        ))}
      </div>
    </section>
  );
}
