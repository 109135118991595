import React from "react";
import { Link } from "react-router-dom";

export const WorkSteps = ({ items, isWindows }) => {
  const email = localStorage.getItem("email");
  const pass = localStorage.getItem("pass");

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }

  const renderButton = () => {
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
    if (isWindows) {
      return (
        <div>
          <h3 className="bottomTitle">Ready to get started?</h3>
          <Link to="/get-started" className="blue-dark-btn margin-auto">
            Get Your Free Quote
          </Link>
        </div>
      );
    } else if (isKitchenApp || true) {
      return (
        <Link to="/get-started" className="blue-btn margin-auto">
          Get Started
        </Link>
      );
    } else {
      return (
        <a href="/design" className="blue-btn margin-auto">
          Get Started
        </a>
      );
    }
  };

  return (
    <section className="work-steps">
      <div className="container">
        <ul>
          {items.map((item, index) => (
            <li data-number={index + 1} key={index}>
              <div className="cnt">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <img alt="" {...item.imgProps} />
            </li>
          ))}
        </ul>
        {renderButton()}
      </div>
    </section>
  );
};
