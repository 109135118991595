import React from "react";

import "./HardwareFinishes.scss";

import HardwareMain from "../../../Assets/images/windows/vinil/Hardware-main.png";
import BrushedNickle from "../../../Assets/images/windows/vinil/Brushed-Nickle.png";
import FauxOrb from "../../../Assets/images/windows/vinil/Faux-Orb.png";
import AntiqueBrass from "../../../Assets/images/windows/vinil/Antique-Brass.png";
import Black from "../../../Assets/images/windows/vinil/black.png";

const hardwareItems = [
  { id: 0, title: "Brushed Nickle", img: BrushedNickle, alt: "BrushedNickle" },
  { id: 1, title: "Faux Orb", img: FauxOrb, alt: "FauxOrb" },
  { id: 2, title: "Antique Brass", img: AntiqueBrass, alt: "AntiqueBrass" },
  { id: 3, title: "Black", img: Black, alt: "Black" },
];

export default function HardwareFinishes() {
  return (
    <section className="wrapperHardware">
      <h4>
        Your hardware finishes are a subtle way to add some elegance to your
        windows and are another way to ensure your new vinyl windows reflect
        your personal style and match your existing interior.
      </h4>

      <div className="hardwareContent">
        <div className="leftContent">
          <h3>
            <b>Hardware Finishes</b>
          </h3>
          <p>
            *Colours may vary from those illustrated due to the printing
            process.
          </p>
          <img src={HardwareMain} />
          <p>
            <b>Standard white</b>
          </p>
        </div>
        <div className="rightContent">
          {hardwareItems.map((item) => (
            <div key={item.id} className="rightContent__item">
              <h3>
                <b>{item.title}</b>
              </h3>
              <img src={item.img} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
