import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import LeftBtn from "../../../Assets/images/windows/home/latestwork-left-btn.png";
import RightBtn from "../../../Assets/images/windows/home/latestwork-right-btn.png";

import "./WindowsOurLatestWork.scss";
const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const WindowsOurLatestWork = ({ items, title, hideButton }) => {
  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
  };
  const sliderRef = useRef();
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <section className="ourLatestWork">
      <h2>{title || "Our Latest Work"}</h2>
      <div className="sliderContainer">
        <div className="arrowLeft" onClick={prevSlide}>
          <img src={LeftBtn} />
        </div>
        <div className="testimonial-slider">
          <Slider
            {...sliderSettings}
            {...additionalSliderSettings}
            ref={sliderRef}
          >
            {items.map((item, index) => (
              <div className="box" key={index}>
                <img src={item} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="arrowRight" onClick={nextSlide}>
          <img src={RightBtn} />
        </div>
      </div>
      {!hideButton && (
        <Link to="/our-work" className="blue-dark-windows-btn">
          <button>View More</button>
        </Link>
      )}
    </section>
  );
};
