import React from "react";

import { WorksGallery } from "../../Containers/OurWork";
import { WindowsIntro } from "../../Containers/OurWork/windows/WindowsIntro";
import { WindowsTopOurWork } from "../../Containers/OurWork/windows/WindowsTopOurWork";



export const OurWorkPage = (props) => {
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const galleryImages = importAll(
    require.context(
      "../../Assets/images/windows/our-works/gallery",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  //* Get started using our 3D Creator or call <a href='tel:{phone}'>{phone}</a> to get a free quote

  return (
    <>
      <WindowsTopOurWork />
      <WorksGallery images={galleryImages} />
      <WindowsIntro />
    </>
  );
};
