import React from "react";
import { useBreakPoint } from "../../hooks/useResponsive";

import "./OurColection.scss";

export default function OurColection({ items, title, subtitle }) {
  const breakPoint = useBreakPoint();

  return (
    <div className="ourColection">
      <h2>{title}</h2>
      {subtitle ? <h3 className="subTitle">{subtitle}</h3> : null}

      {breakPoint !== 980 ? (
        <div className="gridContainer">
          {items.map((item, i) =>
            i <= items.length - 5 ? (
              <div
                key={item.id}
                className="grid__Item"
                style={{ borderBottom: "solid 1px gray" }}
              >
                <img src={item.img} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
            ) : (
              <div key={item.id} className="grid__Item">
                <img src={item.img} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="gridContainer">
          {items.map((item, i) =>
            i <= items.length - 3 ? (
              <div
                key={item.id}
                className="grid__Item"
                style={{ borderBottom: "solid 1px gray" }}
              >
                <img src={item.img} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
            ) : (
              <div key={item.id} className="grid__Item">
                <img src={item.img} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
