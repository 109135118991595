import React from "react";
import { Link } from "react-router-dom";

import "./DoorsNav.scss";

export const DoorsNav = ({ doorMenu, active }) => {
  return (
    <div className="topDoors">
      {doorMenu.map((item) => (
        <div
          key={item.id}
          className={`topDoors__item ${
            item.path && item.path === active ? "active" : ""
          }`}
        >
          <Link to={item.path}>
            <img src={item.img} alt={item.alt} />
            <h4 style={{ color: "#fff", cursor: "pointer" }}>{item.title}</h4>
          </Link>
        </div>
      ))}
    </div>
  );
};
