import React from "react";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import "./WindowsTopSection.scss";

export const WindowsTopSection = ({ doorMenu }) => {
  return (
    <section id="topWindows">
      <div className="banner">
        <h2>How It Works</h2>
        {/* <h3>The Process</h3> */}
      </div>
    </section>
  );
};
