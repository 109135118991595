import React, { useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
// import VideoModal from "../../Common/videoModal";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
    rootD: {
      ['& .MuiPaper-root']: {
          overflow: 'visible',
      }
    },
    customIconCloseChat: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '2px',
        top: "2px",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
    customIconLeft: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        left: '-40px',
        top: "50%",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
    customIconRight: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '-18px',
        top: "50%",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
  });

const Modal = (props) => {
 
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);

    const [isOpenVideo, setOpenVideo] = useState(false);
    const [image, setImage] = useState('');
    const [scrollY, setScrollY] = useState(0);

    const handleClose = () => {
      setOpen(false); 
      setTimeout( e => { window.scrollTo({ top: scrollY }) }, 220);
    };
    const handleNext = () => {
      const { index, items } = props.handler;
      const newIndex = index === items.length-1 ? 0 : index+1;
      props.handler.index = newIndex;
      setImage(items[newIndex]);
    };
    const handlePrev = () => {
      const { index, items } = props.handler;
      const newIndex = index === 0 ? items.length-1 : index-1;
      props.handler.index = newIndex;
      setImage(items[newIndex]);
    };

    if (props && props.handler) {
        props.handler.updateState = ({ contentImage }) => {
            setScrollY(window.scrollY);
            setOpen(!isOpen);
            setImage(contentImage);
        };
        props.handler.updateStateVideo = () => {
          setOpenVideo(!isOpenVideo);
        };
    }

    return (
      <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        className={classes.rootD}
      >
        <DialogContent >
            <img src={image} />
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={handleClose}/>

        <ChevronLeftIcon className={classes.customIconLeft} onClick={handlePrev}/>
        <ChevronRightIcon className={classes.customIconRight} onClick={handleNext}/>

      </Dialog>

      {/* <VideoModal
        isOpen={isOpenVideo}
        videoId={'GAatDIk9fwc'}
        handleClose={ ()=>{ setOpenVideo(false); } }
       /> */}
      </>
    );
};

export default Modal;