import React from "react";

import {
  TopSection,
  Content,
  ContactInfo,
  WhoWeAre,
  OurValues,
  Experience,
  CallToAction,
} from "../../Containers/About";

export const AboutUsPage = (props) => {
  console.log("about props", props);
  let phone;
  let email;
  let locations;

  if (props.data) {
    if (props.isKitchenApp) {
      phone = props.data.kitchen_phone;
    } else {
      phone = props.data.bathroom_phone;
    }

    email = props.data.email;
    locations = props.data.location;
  }

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }

  const callToActionProps = {
    // text: `To find out how decades of trusted industry experience can
    // help your bathroom renovation project take flight, call or
    // reach out online today.`,
    text: (
      <>
        <p>
          It’s never been easier to renovate your home. Get your free quote, and
          our experts will move through your project right alongside you!
        </p>
        <p>
          We make the journey to sparkling windows and stunning doors
          convenient, fun, and, most importantly, easy!
        </p>
      </>
    ),

    className: "windows",
  };

  const contentData = {
    title: "Who Are We?", //"The #1 Bathroom Design & Renovation Experts",
    text: (
      <>
        <p>We’re your go-to window and door renovation team.</p>

        <p>
          We’ve been in the business since 1998 and have elevated the industry
          standard. We’re highly rated and have earned steady recognition for
          our quality craftsmanship and customer service over the last 20 years.
        </p>

        <p>
          Our team of experts can transform your home with breathtaking views
          and luxury doors for unmatched curb appeal!
        </p>
      </>
    ),
  };

  const experienceInfo = {
    paragraph: (
      <>
        <p>
          Nearly 97% of all projects in the home renovation industry are either
          finished late or over budget, with only 2.5% of all projects in North
          America being completed on time without costing you more money!
        </p>
        <p>
          AGM Renovations has a strong reputation for quality and excellence,
          built on many successful years in the industry. Our proven track
          record of finishing projects on time and on budget is second to none.
        </p>
      </>
    ),
  };
  const whoWeAre = {
    paragraphs: (
      <>
        <p>
          Backed by decades of experience in customer satisfaction, it’s no
          surprise we’re highly rated on both Google and HomeStars for our
          commitment to excellence.
        </p>
        <p>
          A large part of our success comes down to our exceptional talent and
          commitment to leadership. The quality of our work and the experience
          we provide you are of the utmost importance.
        </p>
        <p>
          Each member of our staff is an integral part of our team – we never
          use subcontractors!
        </p>
        <p>
          This allows us to facilitate quality control at a grassroots level on
          all aspects of your renovations, allowing us to deliver the final
          result on time, within budget, and in superior quality.
        </p>
      </>
    ),
  };
  const ourValues = {
    btnText: "APPLY TO JOIN OUR TEAM",
  };
  return (
    <>
      <TopSection subtitle="Join Our Team" btnText="Apply Today" />
      <ContactInfo phone={phone} email={email} locations={locations} />
      <Content {...contentData} />
      <WhoWeAre paragraphs={whoWeAre.paragraphs} />
      <OurValues btnText={ourValues.btnText} />
      <Experience {...experienceInfo} />
      <CallToAction {...callToActionProps} />
    </>
  );
};
