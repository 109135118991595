import React from "react";

import "./Grills.scss";

import Colonial from "../../../Assets/images/windows/vinil/colonial.png";
import Prairie from "../../../Assets/images/windows/vinil/prairie-1.png";
import Ladder from "../../../Assets/images/windows/vinil/ladder.png";
import Doubleladder from "../../../Assets/images/windows/vinil/double-ladder.png";
import ExternalSbl from "../../../Assets/images/windows/vinil/external-SDL.jpeg";
import Internal from "../../../Assets/images/windows/vinil/internal.jpeg";
import ExternalMobile from "../../../Assets/images/windows/vinil/external-SDL-mobile.jpeg";
import InternalMobile from "../../../Assets/images/windows/vinil/internal-sdlc-mobile.jpeg";
import { useBreakPoint } from "../../../hooks/useResponsive";

const grillsItems = [
  { id: 0, title: "Colonial", img: Colonial, alt: "Colonial" },
  { id: 1, title: "Prairie", img: Prairie, alt: "Prairie" },
  { id: 2, title: "Ladder", img: Ladder, alt: "Ladder" },
  { id: 3, title: "Double Ladder", img: Doubleladder, alt: "Doubleladder" },
];

export default function Grills() {
  const breakPoint = useBreakPoint();
  return (
    <section className="grillsSection">
      <h4>
        Give your windows a unique character by choosing one of our six internal
        grill options. Internal grills are metal bars that are preassembled and
        placed between the glass panes of your window.
      </h4>
      <div className="grilsWindowsContainer">
        {grillsItems.map((item) => (
          <div key={item.id} className="grilsWindowsContainer__item">
            <img src={item.img} alt={item.alt} />
            <h3>
              <b className="grilsWindowsContainer__item_description">
                {item.title}
              </b>
            </h3>
          </div>
        ))}
      </div>
      <div className="grilsBottomSection">
        <div className="grilsBottomSection__external">
          <h3>
            <b>External SDLC</b>
          </h3>
          <img src={ExternalSbl} alt="external Sdlc" />
        </div>
        <div className="grilsBottomSection__internal">
          {" "}
          <h3>
            <b>Internal</b>
          </h3>
          <img src={Internal} alt="internal" />
        </div>
      </div>
    </section>
  );
}
